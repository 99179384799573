import * as React from "react";
import PageComponent from "src/components/pages/contact";

const Page = ({ location }) => {
  const copy = {
    title: "אנחנו כאן לשירותכם. השאירו פרטים ונחזור אליכם בהקדם:",
    fields: {
      name: "שם מלא",
      phone: "טלפון",
      email: 'דוא"ל',
      message: "הודעה",
    },
    success: (
      <span>
        תודה על פנייתך.
        <br />
        נציגינו יחזרו אליכם בהקדם.
      </span>
    ),
    error: "אירעה שגיאה. אנא נסו שנית במועד מאוחר יותר.",
    button: "שלח",
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="צור קשר"
    />
  );
};

export default Page;
